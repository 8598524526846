<template>
    <div class="about center-wrap">
        <h1>这是about页面</h1>
    </div>
</template>
<script>
export default {
    name: 'About',
    data() {
        return {}
    },
}
</script>
<style scoped></style>